// фиксация меню
let menuScroll = function () {
	let $header = $('.header'),
		$navPanel = $('.header .navbar'),
		headerHeight = $header.outerHeight(),
		navHeight = $navPanel.outerHeight(),
		scroll = 0;

	scroll = $(this).scrollTop();

	if (scroll >= headerHeight - navHeight) {
		if (!$navPanel.hasClass('fixed')) {
			$navPanel.addClass('fixed');
		}
	} else if (scroll < headerHeight - navHeight) {
		if ($navPanel.hasClass('fixed')) {
			$navPanel.removeClass('fixed');
		}
	}
};

// обрезка длинного текста
let textTrim = function (element, size) {
	let text = $(element).text();

	if (text.length > size) {
		element.text(text.slice(0, size) + '...');
	}
};

// вызов формы записи на курс
let courseRecord = function ($button) {
	let courseName = $button.parents('.banner__course').find('.js__courseName').text(),
		$modalCourseName = $('.js__modalCourseName'),
		$courseTitle = $('#courseRecord').find('[name="formData[title]"]');

	$modalCourseName.text(courseName);
	$courseTitle.val(`Запись на курс | ${courseName}`);
};

// вызов вспомогательных форм захвата
let subModalRecord = function ($button) {
	let buttonName = $button.text(),
		subModalLongTitle = $('#subModalLongTitle'),
		$formTitle = $('#subModalForm').find('[name="formData[title]"]');

	subModalLongTitle.text(buttonName);
	$formTitle.val(`Заполнена форма | ${buttonName}`);
};

// проверка на спам ботов
let spamCheck = function ($button) {
	$button.parents('.form').find('[name="formData[check]"]').val('checked');
};

// изменение ссылок
let changeUrl = function (objects) {
	let currentUrl = '';
	objects.each(function (index, object) {
		currentUrl = $(object).attr('href');
		object.href = `/${currentUrl}`;
	});
};

// валидация
function validate(form) {
	$(form).find('.form__error').each(function (index, element) {
		$(element).fadeOut();
	});

	let requiredInput = form.find('.form__input_required'),
		val = true;

	requiredInput.each(function (index, input) {
		if ($(input).attr('type') === 'checkbox') {
			if (!$(input).is(':checked')) {
				$(input).parents('.form__item').find('.js__formErrorRequired').fadeIn();
				val = false;
			}
		} else if ($(input).val().length === 0) {
			$(input).parents('.form__item').find('.js__formErrorRequired').fadeIn();
			val = false;
		}
	});

	if (val) {
		requiredInput.each(function (index, input) {
			if ($(input).attr('name') === 'formData[email]') {
				let patterEmail = /^[a-z0-9_-]+@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/i,
					validEmail = patterEmail.test($(input).val());

				if (!validEmail) {
					$(input).parents('.form__item').find('.js__formErrorValidation').fadeIn();
					val = false;
				}
			}
		});
	}

	return val;
}

(function($) {
	// Lazy Load
	$(function() {
		$('.lazy').Lazy();
	});

	// клик по кнопке отправки формы
	$('form').find('button[type="submit"]').on('click', function () {
		spamCheck($(this));
	});

	$('input[name="formData[policy]"]').click(function () {
		if (!$(this).is(':checked')) {
			$(this).parents('.form').find('button[type="submit"]').attr('disabled', 'disabled');
		} else {
			$(this).parents('.form').find('button[type="submit"]').removeAttr('disabled');
		}
	});

	$('form').on('submit', function (event) {
		event.preventDefault();

		if (!validate($(this))) {
			return false;
		}

		let form = $(this),
			data = $(this).serialize();

		form.find('button[type="submit"]').text('Идёт отправка');

		$.ajax({
			type: "POST",
			url: '/rest.php',
			dataType: "html",
			data: data,
			success: function (result) {
				console.log(result);
				form.find('button[type="submit"]').text('Отправить');
				result = JSON.parse(result);
				if (result.status) {
					form.find('.form__result').html(`<div class="alert alert-success alert-dismissable">
                                                        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                                                        <strong>Форма отправлена!</strong> Мы скоро свяжемся с Вами
                                                    </div>`);
					form[0].reset();
					$(form).find('.form__error').each(function (index, element) {
						$(element).fadeOut();
					});
				} else {
					form.find('.form__result').html(`<div class="alert alert-danger alert-dismissable">
                                                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                                                    <strong>Ошибка!</strong> ${result.error}
                                                </div>`);
					if (form.hasClass('form-success')) {
						form.removeClass('form-success');
					}
				}
			},
			error: function () {
				form.find('.form__result').html(`<div class="alert alert-danger alert-dismissable">
                                                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                                                    <strong>Ошибка!</strong> Попробуйте ещё раз
                                                </div>`);
				form.find('button[type="submit"]').text('Отправить');
			}
		});
	});





	$(document).ready(function() {
		// Плавный переход к якорю
		$(function () {
			if (window.location.pathname === '/') {
				$('.anchor').on('click', function (event) {
					event.preventDefault();
					var sc = $(this).attr("href"),
						dn = $(sc).offset().top;
					$('html, body').animate({scrollTop: dn - 104}, 500);
				});
			}
		});

		// Слайдеры
		$('.main-slider').slick({
			infinite: true,
			arrows: false,
			dots: true,
		});

		$('.main-slider').on('afterChange', function (event, slick, currentSlide) {
			let $activeSliderImage = $('.banner__img.active'),
				$activeSliderDetail = $('.banner__detail.active'),
				$curentSliderImage = $('.banner__img[data-slider-image="' + currentSlide + '"]'),
				$curentSliderDetail = $('.banner__detail[data-slider-detail="' + currentSlide + '"]'),
				$currentSlide = $('.banner .current-slide');
			$activeSliderImage.removeClass('active');
			$activeSliderDetail.removeClass('active');
			$curentSliderImage.addClass('active');
			$curentSliderDetail.addClass('active');
			if (currentSlide + 1 < 10) {
				$currentSlide.html('0' + (currentSlide + 1));
			} else {
				$currentSlide.html(currentSlide + 1);
			}
		});

		$('.team-slider').slick({
			infinite: true,
			arrows: true,
			dots: true,
			slidesToShow: 2,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
						arrows: false,
					}
				},
			]
		});

		$('.gallery-slider').slick({
			centerMode: true,
			infinite: true,
			arrows: true,
			dots: false,
			slidesToShow: 3,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
					}
				},
				{
					breakpoint: 425,
					settings: {
						centerMode: false,
						slidesToShow: 1,
					}
				},
			]
		});

		$('.reviews-slider').slick({
			centerMode: true,
			infinite: true,
			arrows: true,
			dots: false,
			slidesToShow: 5,
			responsive: [
				{
					breakpoint: 1440,
					settings: {
						slidesToShow: 3
					},
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1
					},
				},
				{
					breakpoint: 768,
					settings: {
						centerMode: false,
						slidesToShow: 1
					},
				},
			]
		});

		$('.mobile-courses-slider').slick({
			infinite: true,
			arrows: false,
			dots: true,
			slidesToShow: 1,
		});

		$('.mobile-you-get-slider').slick({
			infinite: true,
			arrows: false,
			dots: true,
			slidesToShow: 1,
		});

		// курсы
		function courseView() {
			let courseWrapper = $(".js__course-container"),
				courseCount = $(".js__course-container").find(".courses__item").length;

			if(courseCount >= 1 && courseCount <= 3 ) {
				$(courseWrapper).css({'grid-template-rows': '1fr','grid-template-areas': '"course-card_1 course-card_2 course-card_3"','height': '347px'});
			}

			if(courseCount >= 4 && courseCount <= 6 ) {
				$(courseWrapper).css({'grid-template-rows': '1fr 1fr 1fr','grid-template-areas': '"course-card_1 course-card_2 course-card_3" "course-card_4 course-card_4 course-card_3" "course-card_5 course-card_6 course-card_6"','height': '1110px'});
			}

			if(courseCount >= 7 && courseCount <= 9 ) {
				$(courseWrapper).css({'grid-template-rows': '1fr 1fr 1fr 1fr','grid-template-areas': '"course-card_1 course-card_2 course-card_3" "course-card_4 course-card_4 course-card_3" "course-card_5 course-card_6 course-card_6" "course-card_7 course-card_8 course-card_9"','height': '1510px'});
			}
		}
		courseView();

		// обрезка текста в курсах
		$('.course-card__description').each(function () {
			textTrim($(this), 96);
		});

		// скроллинг экрана
		$(window).on('scroll', function() {
			menuScroll();
		});

		// инпуты
		$('.js__input').on('focus', function () {
			let $this = $(this),
				$placeholder = $this.siblings('.js__placeholder');

			if ($placeholder.hasClass('form__placeholder_fixed')) {
				return false;
			} else {
				$placeholder.addClass('form__placeholder_fixed');
			}
		});

		$('.js__input').on('blur', function () {
			let $this = $(this),
				$placeholder = $this.siblings('.js__placeholder'),
				inputValue = $this.val();

			if (inputValue.length > 0) {
				return false;
			} else {
				$placeholder.removeClass('form__placeholder_fixed');
			}
		});

		// клик по кнопке записи на курс
		$('.js__recordButton').on('click', function () {
			courseRecord($(this));
		});

		// клик по кнопке вызова вспомогательных форм захвата
		$('.js__subModalButton').on('click', function () {
			subModalRecord($(this));
		});

		if (window.location.pathname !== '/') {
			changeUrl($('.navbar-nav__link'));
		}
	});
})(jQuery);